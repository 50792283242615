import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
export type FdrAirportFragment = {
  __typename?: 'FdrAirport';
  name: string;
  isGroup: boolean;
  iata: string;
  codes?: Array<string> | null;
  coordinates?: {
    __typename?: 'FdrCoordinates';
    longitude: number;
    latitude: number;
  } | null;
};

export const FdrAirportFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrAirport' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrAirport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isGroup' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'codes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
