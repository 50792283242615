import { useCallback } from 'react';
import { isFdrHistoryVisitedProduct } from 'fdr/components/local/fdr-history/fdr-history.types';
import {
  FdrVisitHistoryDocument,
  FdrVisitHistoryQuery,
  FdrVisitHistoryQueryVariables,
} from 'fdr/schemas/query/history/fdr-visit-history.generated';
import {
  getFormattedLocationFromDestination,
  getIconName,
} from './fdr-search-history-group.utils';
import { TSearchHistoryItem } from './fdr-search-history.types';
import { SEARCH_HISTORY_PER_PAGE, useSearchHistory } from './useSearchHistory';

const useVisitedProductHistory = () => {
  const mapToVariables = useCallback(
    (page: number): FdrVisitHistoryQueryVariables => {
      return {
        input: {
          pagination: {
            page,
            perPage: SEARCH_HISTORY_PER_PAGE,
          },
        },
      };
    },
    []
  );

  const mapToPage = useCallback(({ fdrVisitHistory }: FdrVisitHistoryQuery) => {
    return fdrVisitHistory.pagination;
  }, []);

  const mapToItems = useCallback(
    ({ fdrVisitHistory }: FdrVisitHistoryQuery): TSearchHistoryItem[] => {
      return (
        fdrVisitHistory?.entries?.map(item => {
          const object = item?.object;

          if (isFdrHistoryVisitedProduct(object)) {
            if (!object.product) return null;

            return {
              icon: getIconName(
                item.payload?.productSearchCriteria?.travelType,
                object.product.__typename
              ),
              title: object.product.name,
              subtitle: getFormattedLocationFromDestination(object),
              link: object.webMeta.link,
            };
          }

          return null;
        }) || []
      );
    },
    []
  );

  return useSearchHistory<
    FdrVisitHistoryQuery,
    FdrVisitHistoryQueryVariables,
    TSearchHistoryItem
  >({
    documentNode: FdrVisitHistoryDocument,
    mapToVariables,
    mapToPage,
    mapToItems,
  });
};

export default useVisitedProductHistory;
