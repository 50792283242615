import { convertDOBStrToISOStr } from '@hotelplan/components.common.dob-input';
import { formatDate } from '@hotelplan/libs.utils';
import {
  FdrHistoryFlightSearchCriteriaInput,
  FdrHistoryProductSearchCriteriaInput,
  FdrProductTravelType,
} from '@hotelplan/supergraph-api';
import {
  fdrSrlFormToCriteriaPeriod,
  fdrSrlFormToCriteriaTravellers,
} from 'fdr/components/candidate/fdr-search/mappers/fdr-srl-form-to-criteria-input.mapper';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { prepareFdrFlightPartitionsToCriteriaInput } from 'fdr/components/local/fdr-flight-search/flight-partitions/fdr-flight-partitions.utils';

export const mapSearchControlFormStateToHistoryProductSearchCriteria = (
  formState: IFdrSrlControlState
): FdrHistoryProductSearchCriteriaInput => {
  if (formState?.type !== 'HOTEL') return null;

  return {
    travelType: formState?.extended_travelType.fdr as FdrProductTravelType,
    destinations: formState.legacy_travelDestination.ids,
    rooms: formState?.travelRooms?.rooms || 0,
    period: fdrSrlFormToCriteriaPeriod(formState),
    travellers: fdrSrlFormToCriteriaTravellers(formState),
  };
};

export const mapSearchControlFormStateToHistoryFlightSearchCriteria = (
  formState: IFdrSrlControlState
): FdrHistoryFlightSearchCriteriaInput => {
  if (formState?.type !== 'FLIGHT') return null;

  const preparedPartitionsToCriteria =
    prepareFdrFlightPartitionsToCriteriaInput(
      formState.flightPartitions,
      formState.flightType
    );

  return {
    flights: preparedPartitionsToCriteria.map(part => {
      const departureDate = part.travelDates.departureDate;
      const returnDate = part.travelDates.returnDate;

      const formattedDepartureDate = formatDate(departureDate);
      const formattedReturnDate = returnDate
        ? formatDate(returnDate)
        : formattedDepartureDate;

      return {
        flightClass: part.flightClass,
        departureDate: formattedDepartureDate,
        returnDate: formattedReturnDate,
        departureAirportIata: part.departureAirport.iata,
        arrivalAirportIata: part.arrivalAirport.iata,
      };
    }),
    flightType: formState.flightType,
    travellers: {
      adults: formState?.travelRooms?.adults,
      childrenDobs: (formState?.travelRooms?.childrenDobs || []).map(
        convertDOBStrToISOStr
      ),
    },
  };
};
