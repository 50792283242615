import { useTranslation } from 'next-i18next';
import React from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import useFdrAddToHistory from 'fdr/components/local/fdr-history/useFdrAddToHistory';
import FdrSearchHistoryLoginLinks from './fdr-search-history-login-links';
import FdrSearchHistorySearchedProducts from './fdr-search-history-searched-products';
import FdrSearchHistorySkeleton from './fdr-search-history.skeleton';
import { SearchHistoryWrapper } from './fdr-search-history.styles';
import useSearchedProductHistory from './useSearchedProductHistory';
import useVisitedProductHistory from './useVisitedProductHistory';

export interface ISearchHistoryProps {
  onClick?(): void;
  className?: string;
}

export const FdrSearchHistory: React.FC<ISearchHistoryProps> = props => {
  const { onClick, className } = props;
  const [t] = useTranslation('common');
  const { channelType } = useAuthentication();
  const b2cAnonymous = channelType === AuthChannelType.B2CAnonymous;

  const addToHistory = useFdrAddToHistory();

  const visitHistory = useVisitedProductHistory();
  const searchHistory = useSearchedProductHistory();

  const loading = visitHistory.loading && searchHistory.loading;

  const onHistoryItemClick = (searchState?: IFdrSrlControlState) => {
    if (searchState) {
      addToHistory(searchState);
    }
    onClick && onClick();
  };

  return (
    <SearchHistoryWrapper className={`historyContainer ${className}`}>
      {loading && <FdrSearchHistorySkeleton />}
      {!loading && (
        <>
          <FdrSearchHistorySearchedProducts
            items={visitHistory.items}
            header={t('common:recentProducts.heading')}
            noItemsMessage={t('common:history.products.empty')}
            onHistoryItemClick={onHistoryItemClick}
            testId="history-products"
            loading={visitHistory.loading}
            onNextPage={
              visitHistory.nextPage ? visitHistory.onNextPage : undefined
            }
          />
          <FdrSearchHistorySearchedProducts
            items={searchHistory.items}
            header={t('common:recentSearch.heading')}
            noItemsMessage={t('common:history.searches.empty')}
            onHistoryItemClick={onHistoryItemClick}
            testId="history-searches"
            loading={searchHistory.loading}
            onNextPage={
              searchHistory.nextPage ? searchHistory.onNextPage : undefined
            }
          />
          {b2cAnonymous && <FdrSearchHistoryLoginLinks />}
        </>
      )}
    </SearchHistoryWrapper>
  );
};
