import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { Greetings } from '@hotelplan/components.common.greetings';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useFdrAccountPersonalProfileQuery } from 'fdr/schemas/query/account/personal/fdr-account-personal-profile.generated';
import { FdrGreetingsSkeleton } from './fdr-account-greetings.skeleton';

export function FdrAccountGreetings(): ReactElement {
  const [t] = useTranslation('common');

  const { channelType } = useAuthentication();
  const isAuthenticated = channelType === AuthChannelType.B2C;

  const { data, loading } = useFdrAccountPersonalProfileQuery({
    skip: !isAuthenticated,
  });

  const { firstName, lastName } =
    data?.fdrAccount?.profile?.personalData?.accountCustomer || {};

  return (
    <Greetings
      greetingsTextTranslation={t('common:greetings')}
      firstName={firstName}
      lastName={lastName}
      loading={loading}
      placeholder={<FdrGreetingsSkeleton />}
    />
  );
}
