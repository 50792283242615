import { useApolloClient } from '@apollo/client';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { FdrVisitHistoryObjectType } from '@hotelplan/supergraph-api';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import { useFdrAddSearchHistoryMutation } from 'fdr/schemas/query/history/fdr-add-search-history.generated';
import { useFdrAddVisitHistoryMutation } from 'fdr/schemas/query/history/fdr-add-visit-history.generated';
import {
  mapSearchControlFormStateToHistoryFlightSearchCriteria,
  mapSearchControlFormStateToHistoryProductSearchCriteria,
} from './fdr-history.utils';

const useFdrAddToHistory = () => {
  const { id } = useObjectId();
  const pageType = usePageType<EPageType>();

  const client = useApolloClient();

  const [addVisitHistory] = useFdrAddVisitHistoryMutation();
  const [addSearchHistory] = useFdrAddSearchHistoryMutation();

  return async (formState: IFdrSrlControlState) => {
    if (formState.type === 'HOTEL') {
      if (
        pageType &&
        [
          EPageType.Orl,
          EPageType.Pdp,
          EPageType.PdpDescription,
          EPageType.PdpRegion,
          EPageType.PdpReviews,
        ].includes(pageType)
      ) {
        await addVisitHistory({
          variables: {
            input: {
              objectId: id,
              objectType: FdrVisitHistoryObjectType.Product,
            },
          },
        });

        client.refetchQueries({
          updateCache: cache => {
            cache.evict({
              fieldName: `fdrVisitHistory`,
            });
          },
        });
      } else {
        await addSearchHistory({
          variables: {
            input: {
              productSearchCriteria:
                mapSearchControlFormStateToHistoryProductSearchCriteria(
                  formState
                ),
            },
          },
        });

        client.refetchQueries({
          updateCache: cache => {
            cache.evict({
              fieldName: `fdrSearchHistory`,
            });
          },
        });
      }
    } else {
      await addSearchHistory({
        variables: {
          input: {
            flightSearchCriteria:
              mapSearchControlFormStateToHistoryFlightSearchCriteria(formState),
          },
        },
      });

      client.refetchQueries({
        updateCache: cache => {
          cache.evict({
            fieldName: `fdrSearchHistory`,
          });
        },
      });
    }
  };
};

export default useFdrAddToHistory;
