import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrVisitHistoryQueryVariables = Types.Exact<{
  input: Types.FdrVisitHistoryInput;
}>;

export type FdrVisitHistoryQuery = {
  __typename?: 'Query';
  fdrVisitHistory: {
    __typename?: 'FdrVisitHistoryResponse';
    success: boolean;
    message?: string | null;
    entries?: Array<{
      __typename?: 'FdrVisitHistory';
      id: string;
      object:
        | { __typename?: 'FdrHistoryVisitedAgency' }
        | { __typename?: 'FdrHistoryVisitedGeo' }
        | {
            __typename: 'FdrHistoryVisitedProduct';
            product?:
              | {
                  __typename: 'FdrCruise';
                  name: string;
                  id: string;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    country: { __typename?: 'FdrCountry'; name: string };
                  };
                }
              | {
                  __typename: 'FdrHotel';
                  name: string;
                  id: string;
                  resort: {
                    __typename?: 'FdrResort';
                    name: string;
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      country: { __typename?: 'FdrCountry'; name: string };
                    };
                  };
                }
              | {
                  __typename: 'FdrRoundTrip';
                  name: string;
                  id: string;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    country: { __typename?: 'FdrCountry'; name: string };
                  };
                }
              | { __typename: 'FdrAdventureTravel'; name: string; id: string }
              | null;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          }
        | { __typename?: 'FdrHistoryVisitedStaticPage' }
        | { __typename?: 'FdrHistoryVisitedTheme' };
      payload?: {
        __typename?: 'FdrVisitHistoryPayload';
        productSearchCriteria?: {
          __typename?: 'FdrHistoryProductSearchCriteria';
          travelType: Types.FdrProductTravelType;
        } | null;
      } | null;
    }> | null;
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
  };
};

export type FdrHistoryVisitedProductFragment = {
  __typename: 'FdrHistoryVisitedProduct';
  product?:
    | {
        __typename: 'FdrCruise';
        name: string;
        id: string;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          country: { __typename?: 'FdrCountry'; name: string };
        };
      }
    | {
        __typename: 'FdrHotel';
        name: string;
        id: string;
        resort: {
          __typename?: 'FdrResort';
          name: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            country: { __typename?: 'FdrCountry'; name: string };
          };
        };
      }
    | {
        __typename: 'FdrRoundTrip';
        name: string;
        id: string;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          country: { __typename?: 'FdrCountry'; name: string };
        };
      }
    | { __typename: 'FdrAdventureTravel'; name: string; id: string }
    | null;
  webMeta: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
};

export const FdrHistoryVisitedProductFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrHistoryVisitedProduct' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHistoryVisitedProduct' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resort' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'destination' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'name' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'destination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'country' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrVisitHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrVisitHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrVisitHistoryInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrVisitHistory' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'entries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'object' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'FdrHistoryVisitedProduct',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'fdrHistoryVisitedProduct',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'payload' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'productSearchCriteria',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'travelType' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrHistoryVisitedProductFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrVisitHistoryQuery__
 *
 * To run a query within a React component, call `useFdrVisitHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrVisitHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrVisitHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFdrVisitHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrVisitHistoryQuery,
    FdrVisitHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrVisitHistoryQuery,
    FdrVisitHistoryQueryVariables
  >(FdrVisitHistoryDocument, options);
}
export function useFdrVisitHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrVisitHistoryQuery,
    FdrVisitHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrVisitHistoryQuery,
    FdrVisitHistoryQueryVariables
  >(FdrVisitHistoryDocument, options);
}
export type FdrVisitHistoryQueryHookResult = ReturnType<
  typeof useFdrVisitHistoryQuery
>;
export type FdrVisitHistoryLazyQueryHookResult = ReturnType<
  typeof useFdrVisitHistoryLazyQuery
>;
export type FdrVisitHistoryQueryResult = Apollo.QueryResult<
  FdrVisitHistoryQuery,
  FdrVisitHistoryQueryVariables
>;
