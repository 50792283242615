import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrAuthLink } from '@hotelplan/fdr.regular.basis.fdr-auth-link';
import { LOGIN_REDIRECT_PAGE_PARAM_NAME } from '@hotelplan/libs.login-redirect';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import {
  SearchHistoryLinks,
  SearchHistoryLoginLinksImage,
  SearchHistoryLoginLinksWrapper,
} from './fdr-search-history.styles';

const FdrSearchHistoryLoginLinks: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const myAccountHistoryRoute = getRouteByPageTypeLocale(
    EPageType.MyaccountMyhistory,
    language
  );
  const query = {
    [LOGIN_REDIRECT_PAGE_PARAM_NAME]: myAccountHistoryRoute.builder({}),
  };

  return (
    <SearchHistoryLoginLinksWrapper>
      <SearchHistoryLoginLinksImage
        resized={[]}
        src="/images/svg/streamline-icon-responsive-design-1.svg"
      />
      <SearchHistoryLinks>
        <Trans
          i18nKey="common:recent.noAuthNotification"
          components={[
            <FdrAuthLink key="auth-link" language={language} query={query} />,
          ]}
        />
      </SearchHistoryLinks>
    </SearchHistoryLoginLinksWrapper>
  );
};

export default FdrSearchHistoryLoginLinks;
