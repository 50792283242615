import React, { FC } from 'react';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { IFdrSrlControlState } from 'fdr/components/candidate/fdr-search/types/union-state-form.types';
import FdrSearchHistoryGroup from './fdr-search-history-group';
import {
  SearchHistoryIconWrapper,
  SearchHistoryLinkItemWrapper,
  SearchHistoryLinkWrapper,
} from './fdr-search-history.styles';
import { TSearchHistoryItem } from './fdr-search-history.types';

interface IFdrSearchHistorySearchedProducts {
  items: TSearchHistoryItem[];
  header?: string;
  testId?: string;
  noItemsMessage?: string;
  onHistoryItemClick?: (searchState?: IFdrSrlControlState) => void;
  onNextPage?(): void;
  loading?: boolean;
}

const FdrSearchHistorySearchedProducts: FC<IFdrSearchHistorySearchedProducts> =
  ({
    items,
    header,
    testId,
    noItemsMessage,
    onHistoryItemClick,
    onNextPage,
    loading,
  }) => {
    return (
      <FdrSearchHistoryGroup
        header={header}
        testId={testId}
        noItemsMessage={noItemsMessage}
        onNextPage={onNextPage}
        loading={loading}
      >
        {items.map((item, i) => {
          if (!item) return null;

          return (
            <FdrRoute link={item.link} key={JSON.stringify({ i, item })}>
              <SearchHistoryLinkWrapper
                onClick={() => onHistoryItemClick && onHistoryItemClick()} // todo: check with Pavel U. that nothing should be mapped here
              >
                <SearchHistoryIconWrapper
                  name={item.icon}
                  vertical={item.icon.includes('vertical')}
                />
                <SearchHistoryLinkItemWrapper>
                  <div>{item.title}</div>
                  <div className="subtitle">{item.subtitle}</div>
                </SearchHistoryLinkItemWrapper>
              </SearchHistoryLinkWrapper>
            </FdrRoute>
          );
        })}
      </FdrSearchHistoryGroup>
    );
  };

export default FdrSearchHistorySearchedProducts;
